<template>
  <div>
    <b-row>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.totalBalance).toLocaleString()" />
          <span v-text="$t('total_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.ownBalance).toLocaleString()" />
          <span v-text="$t('own_balance')" />
        </b-card>
      </b-col>
      <b-col
        v-if="userData.role !== 'company' && userData.role !== 'agent' && userData.role !== 'partner'"
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.companyBalance).toLocaleString()" />
          <span v-text="$t('company_balance')" />
        </b-card>
      </b-col>
      <b-col
        v-if="userData.role !== 'partner'"
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.agentBalance).toLocaleString()" />
          <span v-text="$t('agent_balance')" />
        </b-card>
      </b-col>
      <b-col
        v-if="userData.role !== 'partner'"
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.partnerBalance).toLocaleString()" />
          <span v-text="$t('partner_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.memberBalance).toLocaleString()" />
          <span v-text="$t('member_balance')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfBet).toLocaleString()" />
          <span v-text="$t('today_no_of_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayNoOfPlayer).toLocaleString()" />
          <span v-text="$t('today_no_of_player')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayBet).toLocaleString()" />
          <span v-text="$t('today_bet')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(report.todayRolling).toLocaleString()" />
          <span v-text="$t('today_rolling')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2
            :class="(report.todayWinLoss < 0) ? 'text-lose-result-color' : ''"
            v-text="Number(report.todayWinLoss).toLocaleString()"
          />
          <span v-text="$t('today_win_loss')" />
        </b-card>
      </b-col>
      <b-col
        sm="6"
        md="3"
        lg="3"
      >
        <b-card
          class="text-center"
        >
          <h2 v-text="Number(onlineUsers.length).toLocaleString()" />
          <span v-text="$t('online_users')" />
        </b-card>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="12"
        class="mt-2"
      >
        <h4>Daily Win/Lose</h4>
        <b-overlay
          :show="loading"
          opacity="0.80"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <div class="vgt-wrap">
                <div class="vgt-inner-wrap">
                  <div class="vgt-responsive game-history-table">
                    <table class="vgt-table bordered">
                      <thead>
                        <tr>
                          <th
                            class="vgt-left-align"
                          >
                            <span>#</span>
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_date')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_winlose')" />
                          </th>
                          <th
                            class="vgt-left-align"
                          >
                            <span v-text="$t('dashboard_total')" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in items"
                          :key="item.id"
                        >
                          <td class="vgt-left-align">
                            <span v-text="item.count" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="item.date_report" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.winlose).toLocaleString()" />
                          </td>
                          <td class="vgt-left-align">
                            <span v-text="Number(item.total).toLocaleString()" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <b-pagination
                v-model="filter.page"
                :per-page="perPage"
                hide-goto-end-buttons
                :total-rows="totalPage"
                align="center"
                @change="loadDailyWinLoseData"
              />
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BPagination,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import 'vue-good-table/dist/vue-good-table.css'
import { EventBus } from '../../eventbus'

export default {
  name: 'Dashboard',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BPagination,
  },
  data() {
    return {
      socket: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      onlineUsers: [],
      report: {
        totalBalance: 0,
        ownBalance: 0,
        companyBalance: 0,
        agentBalance: 0,
        partnerBalance: 0,
        memberBalance: 0,
        todayNoOfBet: 0,
        todayNoOfPlayer: 0,
        todayBet: 0,
        todayRolling: 0,
        todayWinLoss: 0,
      },
      loading: true,
      items: [],
      perPage: 15,
      totalPage: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      currentFilter: {
        page: 1,
      },
    }
  },
  mounted() {
    this.loadData()
    this.loadDailyWinLoseData()
  },
  methods: {
    async loadData() {
      EventBus.$emit('getMyUpdatedUserData')
      const response = await Account.getDashboard()
      this.report = response.data.report
      this.onlineUsers = response.data.onlineUsers
    },
    async loadDailyWinLoseData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })

      this.$router.replace({ query: searchParams })
      const response = await Account.getDailyWinLose(searchParams)

      this.items = response.data.data
      this.totalPage = response.data.meta.pagination.total
      this.perPage = response.data.meta.pagination.per_page

      this.loading = false
    },
  },
}
</script>

<style>
</style>
